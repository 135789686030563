import React from 'react'
import { faBolt, faChartLine, faDesktop, faHandPointer, faLightbulb, faPowerOff, faRotate, faSun } from '@fortawesome/free-solid-svg-icons'
import SolutionsLayout from '../components/layouts/solutionsLayout'
import IMG from '../assets/images/residential.jpg'
import IMG1 from '../assets/images/singlephase.png'
import IMG2 from '../assets/images/threephase.png'

const Residential = () => {

  const data = {
    title: 'Residential ESS',
    link: 'batterystorage/residentialess',
    parent: 'Battery Storage',
    parentLink: 'batterystorage',
    // images: [RES, RES1, RES2],
    images: [IMG, IMG1, IMG2],
    description: 'To store the electric power collected from solar panel, grid, or diesel generators. WASPO Energy Storage System (ESS) can save your electric bill a lot, in case of peak load tariff. Meanwhile, in combination with our emergency power solution, you completely secure your home and protect yourself from potential power outages. The smart system ensures a safety, stable and reliable operating environment. Thanks to our intelligent and experienced R&D experts, who made it possible of all the key system an independent technology. (BMS EMS PCS) It will be easy to upgrade or customized based on various requirements. Installation and maintenance will be easy as well with the integrated design.',
    feature: [
      {
        icon: faRotate,
        title: 'HIGH EFFECIENCY',
        description: 'MAX EFFECIENCY > 98.5%',
        style: 'fa-flip'
      },
      {
        icon: faHandPointer,
        title: 'EASY INSTALLATION',
        description: 'Simple Operation/low maintenance',
        style: 'fa-beat'
      },
      {
        icon: faDesktop,
        title: 'SMART SYSTEM',
        description: 'Independent BMS EMS PCS technology. Stable and Reliable',
        style: 'fa-bounce'
      },
      {
        icon: faChartLine,
        title: 'LONG LIFE CYCLE',
        description: 'More than 6000 cycles, 10 years warranty',
        style: 'fa-beat-fade'
      }
    ],
    scenarios: [
      {
        icon: faSun,
        title: 'PV CHARGE',
        description: 'Store the clean energy from into batteries',
        style: 'fa-flip'
      },
      {
        icon: faLightbulb,
        title: 'PEAK LOAD SHIFTING',
        description: 'Power the house during peak electric price period',
        style: 'fa-beat'
      },
      {
        icon: faPowerOff,
        title: 'ON/OFF GRID',
        description: 'Available for On grid or Off grid',
        style: 'fa-bounce'
      },
      {
        icon: faBolt,
        title: 'ON GRID INCHARGE',
        description: 'Charge the battery from the grid when it needed',
        style: 'fa-beat-fade'
      }
    ],
  }
  return (
    <>
      <SolutionsLayout data={data} />
    </>
  )
}

export default Residential