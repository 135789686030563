import React from 'react'
import AMI from '../assets/images/ami.jpg'
import { Breadcrumb, Card } from 'flowbite-react'
import IMG1 from '../assets/images/comm1.jpg'
import IMG2 from '../assets/images/comm2.jpg'
import IMG3 from '../assets/images/comm3.jpg'


const AMICommunication = () => {
  return (
    <div className='min-h-screen w-full mt-24 lg:px-56 px-5'>
      <h1 className='flex text-center justify-center text-3xl font-bold'>AMI/Communication</h1>
      <div className='mt-10'>
        <Breadcrumb className='font-black text-2xl'>
          <Breadcrumb.Item href='/'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/solutions`} >
            Solutions
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/solutions/digitalenergy`} >
            Digital Energy
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/solutions/digitalenergy/amicommunication`} >
            AMI/Communication
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='divider h-[1px] bg-black mt-8'></div>
      <h1 className='pt-10 font-bold text-2xl text-center'>AMI System</h1>
      <img src={AMI} alt='ami' className='w-full mt-8' />
      <p className='font-semibold'>AMI system is an information platform that integrates intelligent metering, intelligent communication, network between metering equipment
        and communication equipment, network between communication equipment and data center, metering data management system and other contents.<br /><br />
        Comcore AMI supports three types of communication networks: wireless, power line, and mobile. Data is transferred over the communication network to the AMI system platform,
        which supports a variety of communication protocols and is preconfigured with extensive analytics capabilities to help managers make decisions.
      </p>
      <div className='flex flex-col'>
        <h1 className='font-bold text-2xl text-center py-5'>Feature</h1>
        <div className='flex flex-col'>
          <div className='flex flex-row py-2 px-2 bg-gray-200 justify-between rounded-t-md lg:gap-0 gap-2'>
            <p>Data Collection</p>
            <p>Automatic or on-demand data collection; Support minute level data collection.</p>
          </div>
          <div className='flex flex-row py-2 px-2 bg-white justify-between lg:gap-0 gap-2'>
            <p>Remote Control</p>
            <p className='justify-start items-start text-start flex'>Remote relay on/off, meter parameter setting, and firmware upgrade.</p>
          </div>
          <div className='flex flex-row py-2 px-2 bg-gray-200 justify-between lg:gap-0 gap-2'>
            <p>Abnormal Alarm</p>
            <p>Monitoring and reporting of abnormal events such as tamper and power off.</p>
          </div>
          <div className='flex flex-row py-2 px-2 bg-white justify-between lg:gap-0 gap-2'>
            <p>Line Loss Analysis</p>
            <p>Analysis of energy loss in power grid based on meter data.</p>
          </div>
          <div className='flex flex-row py-2 px-2 bg-gray-200 justify-between lg:gap-0 gap-2'>
            <p>STS Prepaid</p>
            <p>Support remote prepaid function and on-site CIU control.</p>
          </div>
          <div className='flex flex-row py-2 px-2 bg-white justify-between lg:gap-0 gap-2'>
            <p>Energy Analysis</p>
            <p>Muti-dimensional energy consumption analysis and customized report.</p>
          </div>
          <div className='flex flex-row py-2 px-2 bg-gray-200 justify-between lg:gap-0 gap-2 rounded-b-md'>
            <p>Open API</p>
            <p>Support the integration with customer billing system, vending system, etc.</p>
          </div>
        </div>
      </div>
      <div className='divider h-[1px] bg-black mt-8'></div>
      <h1 className='py-10 font-bold text-2xl text-center'>Communication Solution</h1>
      <div className='flex flex-col gap-5'>
        <Card
          className="w-full"
          imgAlt="wireless solution"
          imgSrc={IMG1}
        >
          <h5 className="text-2xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
           1- Wireless Solution
          </h5>
          <p className="text-center font-normal text-gray-700 dark:text-gray-400">
          The wireless communication solution is mainly suitable for the scenario where the users are concentrated and the power line network is relatively complex. Wireless communication has the characteristics of dynamic networking and flexible deployment. After installation, the wireless communication network is constructed by itself, which is not limited by the signal base station and does not require the erection of a large number of expensive network coverage equipment. Compared with wired construction, the cost is lower.
          </p>
        </Card>
        <Card
          className="w-full"
          imgAlt="powerline communication"
          imgSrc={IMG2}
        >
          <h5 className="text-2xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
           2- Powerline Communication
          </h5>
          <p className="text-center font-normal text-gray-700 dark:text-gray-400">
          The powerline communication and dual-mode solution is mainly suitable for the scenario where users are concentrated and the power grid signal quality is good. The scheme has the advantages of high transmission rate, simple construction and low investment. It can realize the functions of remote batch upgrade and minute level data acquisition, and has obvious communication advantages under the same transformer.
          </p>
        </Card>
        <Card
          className="w-full"
          imgAlt="mobile network solution"
          imgSrc={IMG3}
        >
          <h5 className="text-2xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
           3- Mobile Network Solution
          </h5>
          <p className="text-center font-normal text-gray-700 dark:text-gray-400">
          The mobile network communication solution is mainly suitable for the scenarios with dispersed users, large amount of communication data and high real-time requirements. The NB, 4G and 5G communication networks of operators are used to realize high-speed real-time communication with big data, which is particularly suitable for solving the problems of meter reading planning, scattered user distribution points and monitoring of special measuring points of power companies.
          </p>
        </Card>

      </div>
    </div>
  )
}

export default AMICommunication