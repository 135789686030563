import { Breadcrumb } from 'flowbite-react'
import React from 'react'
import { LuDownload } from "react-icons/lu";

const ResidentialLayout = (props) => {
    return (
        <div className='min-h-screen w-full mt-16 lg:mt-24 lg:px-56 px-5'>
            <h1 className='flex text-center justify-center text-2xl font-bold'>{props.data?.title}</h1>
            <div className='mt-10'>
                <Breadcrumb className='font-black text-2xl'>
                    <Breadcrumb.Item href='/'>
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/solutions`} >
                        Solutions
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/solutions/batterystorage`} >
                        Battery Storage
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/solutions/batterystorage/residentialess`} >
                        Residential ESS
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/solutions/residentialess/${props.data?.link}`} >
                        {props.data?.title}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <div className="border border-gray-300 rounded-lg overflow-hidden shadow-md mt-10 mb-5">
                <img
                    loading='lazy'
                    src={props.data?.img}
                    alt={props.data?.title}
                    className="w-full object-cover"
                />
            </div>
            <div className='w-full lg:mt-10 flex lg:flex-row flex-col'>
                <div className="flex-1">
                    <ul className="list-disc ml-5">
                        {props.data?.description?.map((desc => {
                            return (
                                <li className='font-semibold'>{desc}</li>
                            )
                        }))}
                    </ul>
                </div>
                <div className="flex-1 lg:mt-0 mt-5">
                    <div class="relative overflow-x-auto">
                        <table class="w-full text-sm text-left text-black">
                            <thead class="text-xs text-white uppercase bg-blue-950 dark:bg-black">
                                <tr>
                                    <th scope="col" class="px-6 py-3">
                                        {props.data?.table?.col1}
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        {props.data?.table?.col2}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data?.table?.col?.map((val => {
                                    return (
                                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" class="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-white">
                                                {val?.name}
                                            </th>
                                            <td class="px-6 py-4">
                                                {val?.value}
                                            </td>
                                        </tr>
                                    )
                                }))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {props.data.pdf && 
            <>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <div className='w-full mt-10 flex lg:flex-row flex-col gap-10 mb-5 lg:mb-5'>
                <div className="flex-1">
                    <h2 className='font-bold text-xl'>Data Download</h2>
                </div>
                <div className="flex-1">
                    <a href={props.data?.pdf} download={props.data.title + '.pdf'} className='flex flex-row items-center gap-1'>
                        <h2 className='font-bold text-l'>{props.data.title}</h2>
                        <LuDownload size={20} />
                    </a>
                </div>
            </div>
            </>}
        </div>
    )
}

export default ResidentialLayout