import React, { useState } from 'react'
import { Breadcrumb, Modal } from 'flowbite-react'
import SEM1 from '../assets/images/meteringEquipment/sem/higheff01.png'
import SEM2 from '../assets/images/meteringEquipment/sem/higheff02.png'
import SEM3 from '../assets/images/meteringEquipment/sem/higheff03.png'
// import IMG4 from '../assets/images/meteringEquipment/sem/higheff04.png'
// import IMG5 from '../assets/images/meteringEquipment/sem/higheff05.png'
// import IMG6 from '../assets/images/meteringEquipment/sem/higheff06.png'
// import IMG7 from '../assets/images/meteringEquipment/sem/higheff07.png'
// import IMG8 from '../assets/images/meteringEquipment/sem/higheff08.png'
// import IMG9 from '../assets/images/meteringEquipment/sem/higheff09.png'
// import IMG10 from '../assets/images/meteringEquipment/sem/higheff10.png'
import SGM1 from '../assets/images/meteringEquipment/sgm/sgm1.png'
import SGM2 from '../assets/images/meteringEquipment/sgm/sgm2.png'
import SGM3 from '../assets/images/meteringEquipment/sgm/sgm3.png'
import SHP1 from '../assets/images/meteringEquipment/shp/shp1.png'
import SHP2 from '../assets/images/meteringEquipment/shp/shp2.png'
import SWM1 from '../assets/images/meteringEquipment/swm/swm1.png'
import SWM2 from '../assets/images/meteringEquipment/swm/swm2.png'
import SWM3 from '../assets/images/meteringEquipment/swm/swm3.png'

const sgm = [{ img: SGM1, name: 'Industrial and Commercial IoT Gas Meter' }, { img: SGM2, name: 'IoT Gas Meter for Civil Use' }, { img: SGM3, name: 'Ultrasonic Gas Meter' }]

const sem = [{ img: SEM1, name: 'TA35' }, { img: SEM2, name: 'TA27' }, { img: SEM3, name: 'TA73' }]

const shp = [{ img: SHP1, name: 'RWN100 Data Collector' }, { img: SHP2, name: 'Ultrasonic Heat Meter DN50-DN300' }]

const swm = [{ img: SWM1, name: 'RWN100 Data Collector' }, { img: SWM2, name: 'Ultrasonic Water Meter DN50-DN300' }, { img: SWM3, name: 'Ultrasonic Water Meter DN15-DN40' }]

const MeteringEquipment = () => {
  const [modal, setModal] = useState(false)
  const data = {
    title: 'Metering Equipment',
    link: 'digitalenergy/meteringequipment',
    parent: 'Digital Energy',
    parentLink: 'digitalenergy',
  }
  return (
    <div className='min-h-screen w-full mt-24 lg:px-56 px-5 mb-5'>
      <h1 className='flex text-center justify-center text-3xl font-bold'>{data?.title}</h1>
      <div className='mt-10'>
        <Breadcrumb className='font-black text-2xl'>
          <Breadcrumb.Item href='/'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href='/solutions'>
            Solutions
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/solutions/${data?.parentLink}`}>
            {data.parent}
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/solutions/${data?.link}`} style={{ fontSize: '50px' }}>
            {data?.title}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='divider h-[1px] bg-black mt-8'></div>
      <h1 className='text-center text-2xl font-semibold py-5'>Smart Electricity Meter</h1>
      <div className='flex flex-col lg:flex-row gap-5'>
        {sem?.map((img, index) => (
          <div onClick={() => setModal(true)} key={index} className='flex flex-col gap-3 text-center text-blue-950 cursor-pointer hover:scale-105'>
            <img
              loading='lazy'
              className='mb-5 lg:max-h-72 max-h-96'
              alt={img?.name}
              src={img?.img}
            />
            <p className='font-semibold underline'>{img?.name}</p>
          </div>
        ))}
      </div>
      <h1 className='text-center text-2xl font-semibold py-5'>Smart Gaz Meter</h1>
      <div className='flex flex-col lg:flex-row gap5'>
        {sgm?.map((img, index) => (
          <div onClick={() => setModal(true)} key={index} className='flex flex-col gap-3 text-center text-blue-950 cursor-pointer hover:scale-105'>
            <img
              loading='lazy'
              className='mb-5 lg:max-h-72 max-h-96'
              alt={img?.name}
              src={img?.img}
            />
            <p className='font-semibold underline'>{img?.name}</p>
          </div>
        ))}
      </div>
      <h1 className='text-center text-2xl font-semibold py-5'>Smart Heating Power</h1>
      <div className='flex flex-col lg:flex-row gap-5 justify-center'>
        {shp?.map((img, index) => (
          <div onClick={() => setModal(true)} key={index} className='flex flex-col gap-3 text-center text-blue-950 cursor-pointer hover:scale-105'>
            <img
              loading='lazy'
              className='mb-5 lg:max-h-72 max-h-96'
              alt={img?.name}
              src={img?.img}
            />
            <p className='font-semibold underline'>{img?.name}</p>
          </div>
        ))}
      </div>
      <h1 className='text-center text-2xl font-semibold py-5'>Smart Water Meter</h1>
      <div className='flex flex-col lg:flex-row gap5'>
        {swm?.map((img, index) => (
          <div onClick={() => setModal(true)} key={index} className='flex flex-col gap-3 text-center text-blue-950 cursor-pointer hover:scale-105'>
            <img
              loading='lazy'
              className='mb-5 lg:max-h-72 max-h-96'
              alt={img?.name}
              src={img?.img}
            />
            <p className='font-semibold underline'>{img?.name}</p>
          </div>
        ))}
      </div>
      <div className='flex justfiy-center items-center h-full'>
        <Modal show={modal} size="md" onClose={() => setModal(false)} popup >
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <h3 className="mb-5 text-xl font-semibold text-blue-950">
                Love to Know More?
              </h3>
              <div className="flex justify-center gap-4">
                <a href='/contacts'><button type="submit" className="max-w-md font-semibold rounded-full px-4 py-2 border border-blue-950 bg-blue-950 text-white hover:bg-white hover:text-blue-950">
                  Contact Us
                </button></a>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default MeteringEquipment