import React from 'react'
import SolutionsLayout from '../components/layouts/solutionsLayout'
import { faShield, faCog, faWrench, faArrowUp, faToolbox, faIndustry, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faUsb } from '@fortawesome/free-brands-svg-icons'
import CIESS from '../assets/images/ciess.png'
import CIESS1 from '../assets/images/ciess1.jpg'
import PDF from '../assets/pdf/ciess.pdf';
import PDF1 from '../assets/pdf/ciess1.pdf'

const Ciess = () => {
  const data = {
    title: 'Commercial & Industrial ESS',
    link: 'batterystorage/ciess',
    parent: 'Battery Storage',
    parentLink: 'batterystorage',
    images: [CIESS, CIESS1],
    description: 'With modular design, WASPO C&I ESS is widely used in scenarios like manufactures, business center, IDC, base station etc. Reliable BMS system and high performance equilibrium technology lead to the system more flexible in configuration, much stronger integration and highly stability. Our range 10ft, 20ft to 40ft',
    pdf: [{title: 'C&I ESS', file:PDF}, {title: 'C&I BESS', file: PDF1}],
    feature: [
      {
        icon: faCog,
        title: 'CUSTOMIZABLE',
        description: 'From 0.5MWh to 2MWh, to be customized based on kinds of requirement',
        style: 'fa-beat-fade'
      },
      {
        icon: faShield,
        title: 'SAFETY & STABLE',
        description: 'LiFePo4 Battery, >6000 cycles/15 years Smart operating system',
        style: 'fa-flip'
      },
      {
        icon: faArrowUp,
        title: 'UPGRADABLE',
        description: 'Modular design, easy to expand capacity. Low maintenance – diagnostic interface available',
        style: 'fa-beat'
      },
      {
        icon: faWrench,
        title: 'EASY INSTALLATION',
        description: 'Quick and cost effective installation, with containers delivered ‘plug and play’',
        style: 'fa-bounce'
      }
    ],
    scenarios: [
      {
        icon: faUsb,
        title: 'MICRO GRID',
        description: 'Community, Village, Small business',
        style: 'fa-spin'
      },
      {
        icon: faBuilding,
        title: 'COMMERCIAL',
        description: 'Office building, Shopping Mall, Stadium',
        style: 'fa-bounce'
      },
      {
        icon: faIndustry,
        title: 'INDUSTRIAL',
        description: 'Factories, Industrial zone, Work shop',
        style: 'fa-beat'
      },
      {
        icon: faToolbox,
        title: 'UTILITIES',
        description: 'Power station, Hospital, School, Charging Pile',
        style: 'fa-flip'
      }
    ],
  }
  return (
      <SolutionsLayout data={data} />
  )
}

export default Ciess