// AnimatedSection.js
import React from 'react';
import { useInView } from 'react-intersection-observer';

const AnimatedSection = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: false, // Allow triggering the animation multiple times
  });

  return (
    <div ref={ref} className={`animated-section ${inView ? 'in-view' : ''}`}>
      {children}
    </div>
  );
};

export default AnimatedSection;
