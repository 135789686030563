import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'flowbite-react';

const Contacts = () => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState()
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleName = (e) => {
    setName(e.target.value)
  }

  const handlePhone = (e) => {
    setPhone(e.target.value)
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleMessage = (e) => {
    setMessage(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:info@waspoenergy.com?subject=Contact Us&body=Name: ${name}%0DEmail: ${email}%0DMessage: ${message}%0APhone: ${phone}`;
    window.location.href = mailtoLink;
  }

  return (
    <>
      <div className='min-h-screen w-full mt-24 lg:px-56 px-5'>
        <h1 className='flex text-center justify-center text-3xl font-bold'>Contact Us</h1>
        <div className='mt-10'>
          {/* <span><a href='/solutions' className='mr-5 text-2xl font-bold'>SOLUTIONS</a><FontAwesomeIcon icon={faArrowRight} /><a href={`/solutions/${props.data?.link}`} className='ml-3 font-bold text-xl'>{props.data?.title}</a></span> */}
          <Breadcrumb className='font-black text-2xl'>
            <Breadcrumb.Item href='/'>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/contacts`} >
              Contact Us
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className='divider h-[1px] bg-black mt-8'></div>
        <div className='w-full mt-10'>
          <div className='flex lg:flex-row flex-col'>
            <div className="flex-1 lg:mb-0 mb-5 text-center lg:text-start">
              <h2 className='font-bold text-2xl text-blue-950'>Contact</h2>
            </div>
            {/* <!-- Column 1 --> */}
            <div className="flex-1 flex flex-col gap-3">
              {/* <!-- Row 1 --> */}
              <h2 className='font-bold text-2xl'>Head Office</h2>
              <div>Authorized regional distributor - Middle East, Africa, & Southeast Asia.</div>
              <div><FontAwesomeIcon icon={faLocationDot} /> Jafza Views 19, Jebel Ali FZE, Dubai, UAE</div>
              <div><FontAwesomeIcon icon={faPhone} />  +971 50 424 2784</div>
              <div><FontAwesomeIcon icon={faEnvelope} />  info@waspoenergy.com</div>
              <h3 className='font-bold'>Share:</h3>
              <div className='flex flex-row gap-1'>
                {/* <Link style={{ paddingRight: 15 }} to='https://www.linkedin.com' target='_blank'><FontAwesomeIcon icon={faLinkedin} size='2x' /></Link> */}
                <Link to='https://instagram.com/waspoenergy?igshid=MzRlODBiNWFlZA==' target='_blank'><FontAwesomeIcon icon={faInstagram} size='2x' /></Link>
                <Link to='https://www.facebook.com/profile.php?id=100094355336556&mibextid=LQQJ4d' target='_blank'><FontAwesomeIcon icon={faFacebook} size='2x' /></Link>
                <Link to='https://www.linkedin.com/' target='_blank'><FontAwesomeIcon icon={faLinkedin} size='2x' /></Link>
              </div>
            </div>
          </div>
        </div>
        <div className='divider h-[1px] bg-black mt-8'></div>
        <div className='w-full mt-10'>
          <div className='flex lg:flex-row flex-col'>
            <div className="flex-1 mb-10 lg:mb-0">
              <h2 className='font-bold text-2xl text-blue-950'>Leave Message</h2>
            </div>
            <div className="flex-1">
              <form onSubmit={handleSubmit} className='flex flex-col gap-3'>
                <div>
                  <label htmlFor="Name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Full Name</label>
                  <input value={name} onChange={(e) => { handleName(e) }} type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required></input>
                </div>
                <div>
                  <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone</label>
                  <input value={phone} onChange={(e) => { handlePhone(e) }} type="number" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required></input>
                </div>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email address</label>
                  <input value={email} onChange={(e) => { handleEmail(e) }} type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required></input>
                </div>
                <div>
                  <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Message</label>
                  <textarea value={message} onChange={(e) => { handleMessage(e) }} type="text" id="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required rows={4}></textarea>
                </div>
                <div>
                  <button type="submit" className="text-white hover:text-blue-950 bg-blue-950 hover:bg-white border border-blue-950 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-5">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contacts