import React from 'react'
import { faBatteryFull, faChartLine, faCircleCheck, faDharmachakra, faGear, faHouseLaptop, faRobot, faRocket, faShieldBlank } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from 'flowbite-react';
import IMG from '../assets/images/whybg.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const whyus = [
  {
    title: 'Safe',
    desc: [{
      head: 'High performance structure',
      body: '* Optimized heat dissipation design - High strength structure design',
      icon: faChartLine
    },
    {
      head: 'Top brand LiFePO4 Battery',
      body: '* Great stability in high temperature',
      icon: faBatteryFull
    },
    {
      head: 'Multiple protection solutions',
      body: '* Accurate and Multi-dimension detection - Emergency & Backup Solution',
      icon: faShieldBlank
    }]
  },
  {
    title: 'Advanced',
    desc: [{
      head: 'Independent technology',
      body: '* 100% self-owned technology(residential ESS & PPS)',
      icon: faRocket
    },
    {
      head: 'Highly integrated',
      body: '* Multi-function, all-in-one',
      icon: faGear
    },
    {
      head: 'Precise matching',
      body: '* Self-developed Inverter & batteries modules accurate matching exactly',
      icon: faDharmachakra
    }]
  },
  {
    title: 'Smart',
    desc: [{
      head: 'Automatic detection',
      body: '* Temperature Voltage, Current detetion automatically',
      icon: faCircleCheck
    },
    {
      head: 'Remote control',
      body: '* APP/WEB',
      icon: faHouseLaptop
    },
    {
      head: 'Automatic control strategy',
      body: '* Multi charge/discharge mode, time of use strategy control automatically',
      icon: faRobot
    }]
  }
]

const WhyUs = () => {
  return (
    <div className='w-full mt-24 lg:px-56 px-5'>
      <h1 className='flex text-center justify-center text-3xl font-bold'>Why Choose Us</h1>
      <div className='mt-10'>
        <Breadcrumb className='font-black text-2xl'>
          <Breadcrumb.Item href='/'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/whywaspo`} >
            Why Us
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='divider h-[1px] bg-black mt-8'></div>
      <div className='bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${IMG})` }}>
          <div className="text-start">
            <div className="bg-opacity-90 py-5 rounded-lg">
              <p className="text-md font-semibold">
                At WASPO, we're dedicated to delivering exceptional value and service to our clients. With a focus on safety, innovation, and reliability, we provide cutting-edge solutions tailored to meet your specific needs. Our team of experts is committed to excellence, ensuring that you receive top-quality products and support every step of the way. Choose WASPO for trusted expertise and unparalleled customer satisfaction.
              </p>
            </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 py-5" style={{ backgroundImage: `url(${IMG})`, backgroundPosition: 'cover' }}>
            {whyus.map((item, index) => (
              <div key={index} className="p-4 border border-gray-300 rounded-lg">
                <h2 className="text-lg font-bold mb-4">{item.title}</h2>
                {item.desc.map((descItem, descIndex) => (
                  <div key={descIndex} className="flex items-center mb-2">
                    <FontAwesomeIcon icon={descItem.icon} className="text-blue-950 mr-2" />
                    <div>
                      <h3 className="text-sm font-semibold">{descItem.head}</h3>
                      <p className="text-xs">{descItem.body}</p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyUs