import { Breadcrumb, Card } from 'flowbite-react'
import React from 'react'
import KNOWLEDGE from '../assets/images/hjtknowledge.jpg'
import BIFACIALITY from '../assets/images/bifaciality.jpg'
import LOGO from '../assets/images/solarenergylogo.jpg'
import PDF from '../assets/pdf/solarenergy.pdf'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { LuDownload } from "react-icons/lu";

const SolarEnergy = () => {

    return (
        <div className='min-h-screen w-full mt-24 lg:px-56 px-5'>
            <h1 className='flex text-center justify-center text-3xl font-bold'>Solar Energy</h1>
            <div className='mt-10'>
                <Breadcrumb className='font-black text-2xl'>
                    <Breadcrumb.Item href='/'>
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/solutions`} >
                        Solutions
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`solutions/solarenergy`} >
                        Solar Energy
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <img src={LOGO} alt='logo' className='mx-auto max-h-20 mt-10 w-72' />
            <iframe className='w-full lg:h-96 mt-8' src="https://www.youtube.com/embed/2_9zQyf2j5I" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <h1 className='font-bold text-xl lg:items-start items-center lg:mb-0 mb-5 mt-10 text-center lg:text-start'>HJT Knowledge</h1>
            <div className='w-full lg:mt-10 flex lg:flex-row flex-col mt-10'>
                <div className="flex-1 lg:mr-10 mr-0">
                    <p className='font-semibold text-l'>HJT cells combine the advantages of crystalline silicon and thin film technologies, with excellent light absorption and passivation effects, and are superior to PERC in efficiency and performance. It is one of the solar cell technologies that increase coversion efficiency and power output to the highest level and also represents the developement direction of the next generation of cell technology platform.</p>
                </div>
                <div className="flex-1 lg:mr-10 mr-0 lg:mt-0 mt-10">
                    {/* <!-- Row 1 --> */}
                    <img src={KNOWLEDGE} alt='Knowledge' />
                </div>
                {/* <!-- Column 1 --> */}
                <div className="flex-1 lg:mt-0 mt-10">
                    {/* <!-- Row 1 --> */}
                    <p className='font-semibold text-l'>
                        The natural bifacial symmetrical structure of HJT cells can effectively Improve the power generation capacity on module's backside. The extremely low temperature coefficient enables modules to maintain stable power generation performance in high temperature environments. Excellent low-light performance increases modules power generation period and further imporves power output.
                    </p>
                </div>
            </div>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <h1 className='font-bold text-xl lg:items-start items-center lg:mb-0 mb-5 mt-10 text-center lg:text-start'>Bifaciality Benefits</h1>
            <div className='w-full lg:mt-10 flex lg:flex-row flex-col'>
                <div className="flex-1">
                    {/* <!-- Row 1 --> */}
                    <p className='font-semibold text-l'>HJT's natural bifacial symmetrycal structure makes the modules' bifacility up to 95%. The power generation of HJT cells per watt is about 3% to 6% higher than that of bifacial PERC cells. In practice, the energy yield gain can reach more than 30% benefiting from the power generation on the back side</p>
                </div>
                <div className="flex-1">
                    <img src={BIFACIALITY} alt='bifaciality' className='max-h-32 w-full lg:mt-0 mt-5' />
                </div>
                {/* <!-- Column 1 --> */}
            </div>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <h1 className='font-bold text-xl lg:items-start items-center lg:mb-0 mb-5 mt-10 text-center lg:text-start'>Advantages of HJT Technology</h1>
            <div className='w-full lg:mt-10 flex flex-col'>
                <div className="flex-">
                    <p className='font-semibold text-l mb-5'>By using doped microcrystalline silicon or doped microcrystalline oxygen (silicon carbide) and further increasing the doping concentration, reduces the doped layer resistance
                        and lifts the light transmission performance, thereby increasing the current density and cell efficiency.
                        <br />
                        HJT solar cells have the merits of high conversion rate, low temperature coefficient, no PID, no LID, and uniform color, etc.
                        Compared to other solar cell technologies, HJT cell production requires only four low-temperature process steps, resulting in higher productivity and lower losses.
                    </p>
                    <div className='flex lg:flex-row flex-col justify-center items-center mb-5 gap-2 w-full'>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center"
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Extreme Temperature Coefficient
                            </h2>
                        </Card>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center"
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Thinner Wafer Adopted
                            </h2>
                        </Card>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center"
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Great Weak Light performance
                            </h2>
                        </Card>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center"
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Ultra-low Carbon Footprint
                            </h2>
                        </Card>
                    </div>
                    <div className='flex lg:flex-row flex-col justify-center items-center mb-5 gap-2 w-full'>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center"
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Extremely High Bifaciality
                            </h2>
                        </Card>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center"
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Unique SMBB Technology
                            </h2>
                        </Card>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center"
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Extremely Low Attenuation
                            </h2>
                        </Card>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center"
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                NO LID <br /> NO PID
                            </h2>
                        </Card>
                    </div>
                </div>
            </div>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <div className='w-full mt-10 flex lg:flex-row flex-col gap-10 mb-5 lg:mb-5'>
                <div className="flex-1">
                    <h2 className='font-bold text-xl'>Data Download</h2>
                </div>
                <div className="flex-1">
                    <a href={PDF} download={'Solar Energy.pdf'} className='flex flex-row items-center gap-1'>
                        <h2 className='font-bold text-l'>Solar Energy</h2>
                        <LuDownload size={20} />
                    </a>
                </div>
            </div>
            <div className='flex lg:flex-row flex-col justify-between gap-5 py-5'>
                    <a href='/solutions/germansolar/highefficiencymodules' alt='learnMoreSolutions' className='bg-white flex items-center flex-row border rounded-lg border-blue-950 text-blue-950 hover:text-white hover:bg-blue-950 px-4 font-semibold py-3'>
                        <p>Learn more about our High Efficiency Modules</p>
                        <HiOutlineArrowRight className="ml-2 h-5 w-5" />
                    </a>
                    <a href='/solutions/germansolar/customizedmodules' alt='learnMoreSolutions' className='bg-white flex items-center flex-row border rounded-lg border-blue-950 text-blue-950 hover:text-white hover:bg-blue-950 px-4 font-semibold py-3'>
                        <p>Learn more about our Customized modules</p>
                        <HiOutlineArrowRight className="ml-2 h-5 w-5" />
                    </a>
            </div>
        </div>
    )
}

export default SolarEnergy