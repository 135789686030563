import React from 'react'
import IMG from '../assets/images/customizedModules.jpg'
import { Breadcrumb } from 'flowbite-react'
import { HiSortAscending } from 'react-icons/hi'
import { GiMicroscopeLens } from "react-icons/gi";
import { MdVerifiedUser, MdSecurity, MdPalette, MdBuild, MdExtension, MdPower, MdAssignment  } from 'react-icons/md'

const CustomizedModules = () => {
    const data = {
        title: 'Customized Modules',
        link: 'germansolar/customizedmodules',
        parent: 'German Solar',
        parentLink: 'germansolar',
        images: [IMG]
      }
      return (
        <div className='min-h-screen w-full mt-24 lg:px-56 px-5 mb-5'>
        <h1 className='flex text-center justify-center text-3xl font-bold'>{data?.title}</h1>
        <div className='mt-10'>
          {/* <span><a href='/solutions' className='mr-5 text-2xl font-bold'>SOLUTIONS</a><FontAwesomeIcon icon={faArrowRight} /><a href={`/solutions/${props.data?.link}`} className='ml-3 font-bold text-xl'>{props.data?.title}</a></span> */}
          <Breadcrumb className='font-black text-2xl'>
            <Breadcrumb.Item href='/'>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item href='/solutions'>
              Solutions
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/solutions/${data?.parentLink}`}>
              {data.parent}
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/solutions/${data?.link}`} style={{ fontSize: '50px' }}>
              {data?.title}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className='divider h-[1px] bg-black mt-8 lg:mb-8 mb-4'></div>
        <div className='w-full 2xl:h-[600px] lg:h-[400px] h-[300px] mb-5'>
            <img
              className='w-full h-full mb-5'
              alt={data?.title}
              src={data?.images}
            />
        </div>
        <p className='font-semibold'>
          Our strong relationship with top material suppliers and our expert knowledge of processing high quality modules, guarantee that GermanSolar is capable of producing any type of solar module, with a wide selection of materials and sizes possible. Our production line is highly automated yet flexible, thus enabling us to produce tailor-made modules to meet market demands. Our strong and versatile design capability can turn your ideas into customized modules for different applications – for roof tile, marine & outdoor use, streetlights, etc. These modules usually require carefully coordinated decorative planning and other connectivity considerations as they are designed to be integrated into larger application units. With our design capability, you can choose the color of your individual module and frame, including the front and back material, to make the module match your application aesthetically. GermanSolar’s customized modules are certified by IEC 61215, 61730, C1D2 & UL 1703, and every module is flashed to ensure its performance and quality.
        </p>
        <div className='divider h-[1px] bg-black mt-8 lg:mb-8 mb-4'></div>
        <h1 className='text-center text-2xl font-semibold py-5'>Our Customized Modules Values</h1>
        <div className='flex flex-col gap-5'>
          <div className='flex lg:flex-row flex-col gap-5'>
            <div className='flex-1 flex flex-col items-center text-center'>
              <MdExtension size={40} className='text-blue-950' />
              <p>Unique design, backed by German standards of engineering</p>
            </div>
            <div className='flex-1 flex flex-col items-center text-center'>
              <MdPower size={40} className='text-blue-950' />
              <p>Use of bypass diodes to avoid hot-spot effect</p>
            </div>
            <div className='flex-1 flex flex-col items-center text-center'>
              <GiMicroscopeLens size={40} className='text-blue-950' />
              <p>EL tested and micro cracks inspected</p>
            </div>
          </div>
          <div className='flex lg:flex-row flex-col lg:mt-5 mt-0 gap-5'>
            <div className='flex-1 flex flex-col items-center text-center'>
              <MdBuild size={40} className='text-blue-950' />
              <p>Easy and quick installation</p>
            </div>
            <div className=' flex-1 flex flex-col items-center text-center'>
              <MdAssignment size={40} className='text-blue-950' />
              <p>Every module flashed</p>
            </div>
            <div className='flex-1 flex flex-col items-center text-center'>
              <MdSecurity size={40} className='text-blue-950' />
              <p>IEC 61215, 61730 & UL 1703 certified</p>
            </div>
          </div>
          <div className='flex lg:flex-row flex-col lg:mt-5 mt-0 gap-5'>
            <div className='flex-1 flex flex-col items-center text-center'>
              <MdPalette size={40} className='text-blue-950' />
              <p>Choices of colors, front and back materials & frames</p>
            </div>
            <div className=' flex-1 flex flex-col items-center text-center'>
              <HiSortAscending size={40} className='text-blue-950' />
              <p>Positive and tight power tolerance: +3%</p>
            </div>
            <div className='flex-1 flex flex-col items-center text-center'>
              <MdVerifiedUser size={40} className='text-blue-950' />
              <p>5-year product warranty and 10-year limited performance warranty</p>
            </div>
          </div>
        </div>
      </div>
      )
    }

export default CustomizedModules