import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import Navigationbar from './components/navbar';
import AboutUs from './pages/aboutus';
import Solutions from './pages/solutions';
import Contacts from './pages/contacts';
import Footer from './components/footer';
import News from './pages/news';
import Sitemap from './pages/sitemap';
import Residential from './pages/residential';
import Ciess from './pages/ciess';
// import Energy from './pages/energy';
import SolarEnergy from './pages/solarEnergy';
import SinglePhase from './pages/singlePhase';
import ThreePhase from './pages/threePhase';
// import SplitEss from './pages/splitESS';
import WhyUs from './pages/whyUs';
import BatteryStorage from './pages/batteryStorage';
import DigitalEnergy from './pages/digitalEnergy';
import MeteringEquipment from './pages/meteringEquipment';
import AMICommunication from './pages/amiCommunication';
import HighEfficiency from './pages/highEfficiency';
import CustomizedModules from './pages/customizedModules';

// import Partners from './pages/partners';

function App() {
  return (
    <>
      <Navigationbar />
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/aboutus' element={<AboutUs />}/>
        <Route path='whywaspo' element={<WhyUs />} />
        <Route path='/solutions' element={<Solutions />} />
        <Route path='/solutions/germansolar' element={<SolarEnergy />} />
        <Route path='/solutions/germansolar/highefficiencymodules' element={<HighEfficiency />} />
        <Route path='/solutions/germansolar/customizedmodules' element={<CustomizedModules />} />
        <Route path='/solutions/batterystorage' element={<BatteryStorage />} />
        <Route path='/solutions/batterystorage/residentialess' element={<Residential />} />
        <Route path='/solutions/batterystorage/residentialess/singlephase' element={<SinglePhase />} />
        <Route path='/solutions/batterystorage/residential/threephase' element={<ThreePhase />} />
        {/* <Route path='/solutions/batterystorage/residential/splitess' element={<SplitEss />} /> */}
        <Route path='/solutions/batterystorage/ciess' element={<Ciess />} />
        <Route path='/solutions/digitalenergy' element={<DigitalEnergy />} />
        <Route path='/solutions/digitalenergy/meteringequipment' element={<MeteringEquipment />} />
        <Route path='/solutions/digitalenergy/amicommunication' element={<AMICommunication />} />

        {/* <Route path='/solutions/energy' element={<Energy />} />
        <Route path='/solarenergy' element={<SolarEnergy />} /> */}
        <Route path='/news' element={<News />} />
        {/* <Route path='/channelpartners' element={<Partners />} /> */}
        <Route path='/contacts' element={<Contacts />} />
        <Route path='/sitemap' element={<Sitemap />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
