import React from 'react'

const Sitemap = () => {
    // const solutions = ['Residential ESS', 'C&I ESS', 'Portable Power Station', 'Energy Storage Batteries']
    const array = [
        {
            title: 'ABOUT US',
            body: 'About Us',
            link: '/aboutus'
        },
        {
            title: 'WHY WASPO',
            body: 'Why Us',
            link: '/whywaspo'
        },
        {
            title: 'SOLUTIONS',
            body: 'Residential ESS C&I ESS Energy Storage Batteries',
            link: '/solutions'
        },
        {
            title: 'SOLAR ENERGY',
            body: 'Solar Energy',
            link: '/solarenergy'
        },
        {
            title: 'NEWS',
            body: 'news',
            link: '/news'
        },
        {
            title: 'CONTACTS',
            body: 'contacts',
            link: '/contacts'
        },
        
    ]
    return (
        <div className='w-full mt-16 min-h-screen lg:mt-24 lg:px-52 px-5'>
            <div>
                <span className='font-bold text-2xl'>SITEMAP</span>
            </div>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <div className='flex flex-col gap-4 lg:gap-10 lg:flex-row mt-20'>
                {array.map(arr =>
                (
                    <div class="w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <a href={arr.link}>
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{arr.title}</h5>
                        </a>
                        <span class="mb-3 text-xl font-normal text-black"><a href={arr.link}>{arr.body}</a></span>
                    </div>
                )
                )}
            </div>
            <div className='flex flex-col gap-4 lg:gap-10 lg:flex-row mt-20'>
                    <div class="w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <a href='/channelpartners'>
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">PARTNERS</h5>
                        </a>
                        <span class="mb-3 text-xl font-normal text-black"><a href='/channelpartners'>Channel Partners</a></span>
                    </div>
            </div>
        </div>
    )
}

export default Sitemap