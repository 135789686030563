import React from 'react'
import ResidentialLayout from '../components/layouts/residentialLayout'
import IMG from '../assets/images/threephase.png'
import PDF from '../assets/pdf/threephase.pdf'

const ThreePhase = () => {
    const data = {
        title: "Three Phase",
        img: IMG,
        pdf: PDF,
        link: 'threephase',
        description: ['Higher Max. PV input power than All-In-One (Single-Phase)', ' Seperatye Hybrid inverter and Battery Storage',
            'Connect directly to PV panels for hourseholds and office applications', 'European local team service (EU and UK).'
        ],
        table: {
                col1: 'PARAMETERS',
                col2: 'VALUE',
                col:
                    [
                    { name: 'Max.Pv Input Power', value: '15 KW' },
                    { name: 'Battery Storage', value: '10.25 KWH' },
                    { name: 'Battery Storage Max Expansion', value: '5*2.05 KWH' },
                    { name: 'Battery Type', value: 'LFP (Lithium iron phosphate battery)' },
                    { name: 'Max Efficiency', value: '97.5%' },
                    { name: 'Long Life Cycle of Battery', value: '>6000' },
                    { name: 'Warranty of Battery', value: '10 years' },
                    ]
        }
    }
  return (
    <>
    <ResidentialLayout data={data}/>
    </>
  )
}

export default ThreePhase