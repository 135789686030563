import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import LOGO from '../assets/images/logo1.svg'
import { faArrowRight, faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const contact = [
    {
      icon: <FontAwesomeIcon icon={faLocationDot} />,
      text: 'Jafza Views 19, Jebel Ali FZE, Dubai, UAE'
    },
    {
      icon: <FontAwesomeIcon icon={faEnvelope} />,
      text: 'info@waspoenergy.com'
    },
    {
      icon: <FontAwesomeIcon icon={faPhone} />,
      text: '+971 50 424 2784'
    }
  ]

  const links = [
    {
      href: '/aboutus',
      title: 'About Us',
    },
    {
      href: '/solutions',
      title: 'Solutions',
    },
    {
      href: '/whywaspo',
      title: 'Why Us',
    },
    {
      href: '/contacts',
      title: 'Contact Us',
    }
  ]
  
  return (
    <footer className='w-full bottom-0 flex flex-col gap-2 bg-blue-950 text-white'>
      <div className='flex lg:flex-row flex-col lg:gap-3 gap-0 py-3 px-0 lg:px-10 lg:justify-start justify-center text-center'>
        <div className='flex-1 flex lg:justify-start justify-center'>
          <a href="/" className="text-xl font-bold ">
            <img loading='lazy' src={LOGO} alt='logo' className='w-46 h-32' />
          </a>
        </div>
        <div className='flex-1 flex flex-col gap-2 lg:mb-0 mb-5'>
          <div>
            <h1 className='font-semibold text-2xl'>Get In Touch</h1>
          </div>
          <div className='flex flex-col gap-1 justify-center items-center' >
            {contact.map((cont, index) => (
              <div className="flex flex-row items-center gap-1" key={index}>
                {cont?.icon}
                <p>{cont?.text}</p>
              </div>
            ))}
            <div className='flex flex-row gap-2 justify-center'>
              <Link to='https://instagram.com/waspoenergy?igshid=MzRlODBiNWFlZA==' target='_blank'><FontAwesomeIcon icon={faInstagram} size='lg' /></Link>
              <Link to='https://www.facebook.com/profile.php?id=100094355336556&mibextid=LQQJ4d' target='_blank'><FontAwesomeIcon icon={faFacebook} size='lg' /></Link>
              <Link to='https://www.linkedin.com/' target='_blank'><FontAwesomeIcon icon={faLinkedin} size='lg' /></Link>
            </div>
          </div>
        </div>
        <div className='flex-1 flex flex-col gap-2'>
          <div>
            <h1 className='font-semibold text-2xl'>Quick Links</h1>
          </div>
          <div className='flex flex-col gap-1 text-center items-center'>
            {links.map((link, i) => (
              <div className='flex flex-row items-center gap-1'key={i}>
                <FontAwesomeIcon icon={faArrowRight} size='xs' />
                <a className='hover:underline w-20' href={link?.href}>{link?.title}</a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='w-full py-2 bg-white text-black text-center'>
        Copyright © {currentYear} WASPO Energy. All Rights Reserved
      </div>
    </footer>
  )
}

export default Footer