import React from 'react'
import ResidentialLayout from '../components/layouts/residentialLayout'
import IMG from '../assets/images/singlephase.png'
import PDF from '../assets/pdf/singlephase.pdf'

const SinglePhase = () => {

    const data = {
        title: "Single Phase",
        img: IMG,
        pdf: PDF,
        link: 'singlephase',
        description: ['All-In-One/Integrated Energy Storage System.', 'Connect directly to PV panels for household s and office applications.',
            'Hybrid - Inverter  and Battery energy storage.', 'CE and TUV Certified.'
        ],
        table: {
                col1: 'PARAMETERS',
                col2: 'VALUE',
                col:
                    [
                    { name: 'Max.Pv Input Power', value: '5 KW' },
                    { name: 'Battery Storage', value: '10 KWH' },
                    { name: 'Battery Storage Max Expansion', value: '6*5 KWH' },
                    { name: 'Battery Type', value: 'LFP (Lithium iron phosphate battery)' },
                    { name: 'Max Efficiency', value: '97.5%' },
                    { name: 'Long Life Cycle of Battery', value: '>6000' },
                    { name: 'Warranty of Battery', value: '10 years' },
                    ]
        }
    }
    return (
        <>
            <ResidentialLayout data={data} />
        </>
    )
}

export default SinglePhase