import React, { useEffect, useRef, useState } from 'react';
import LOGO from '../assets/images/logo1.svg'


const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen((prevIsMobileMenuOpen) => !prevIsMobileMenuOpen);
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <nav className=" bg-blue-950 lg:opacity-90 opacity-100 top-0 w-full z-50 text-white">
      {/* Desktop Navbar */}
      <div className="hidden lg:flex items-center justify-between px-10 py-3 max-h-20 ">
        <div>
          <a href="/" className="text-xl font-bold ">
            <img loading='lazy' src={LOGO} alt='logo' className='w-46 h-32' />
          </a>
        </div>
        <div className="flex gap-2">
          <a href="/" className="py-2 px-3 hover:bg-white hover:text-blue-950 hover:font-semibold text-white rounded-lg">
            Home
          </a>
          <a href="/aboutus" className="py-2 px-3 hover:bg-white hover:text-blue-950 hover:font-semibold text-white rounded-lg">
            About Us
          </a>
          <div
            className="relative inline-block z-90"
            onMouseEnter={handleDropdownOpen}
            onMouseLeave={handleDropdownClose}
          >
            <button className="py-2 px-3 hover:bg-white hover:text-blue-950 hover:font-semibold text-white rounded-lg">
              <a href='/solutions'>Solutions</a>
              <svg
                className="w-4 h-4 inline-block ml-1 -mt-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isDropdownOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 15l7-7 7 7"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                )}
              </svg>
            </button>
            {isDropdownOpen && (
              <div className="absolute top-full left-0 bg-blue-950 border border-gray-700 rounded-lg lg:w-52 z-50">
                <a
                  href="/solutions/germansolar"
                  className="block py-2 px-4 hover:bg-white rounded-t-lg hover:text-blue-950 hover:font-semibold text-white"
                >
                  Solar Energy
                </a>
                <div className='ml-5 left-0'>
                  <a
                    href="/solutions/germansolar/highefficiencymodules"
                    className="block py-1 px-4 hover:bg-white hover:text-blue-950 text-sm hover:font-semibold  text-white"
                  >
                    High Efficiency Modules
                  </a>
                  <a
                    href="/solutions/germansolar/customizedmodules"
                    className="block py-1 px-4 hover:bg-white hover:text-blue-950 text-sm hover:font-semibold text-white"
                  >
                    Customized Modules
                  </a>
                </div>
                <a
                  href="/solutions/batterystorage"
                  className="block py-2 px-4 hover:bg-white hover:text-blue-950 hover:font-semibold  text-white"
                >
                  Battery Energy Storage
                </a>
                <div className='ml-5 left-0'>
                  <a
                    href="/solutions/batterystorage/residentialess"
                    className="block py-1 px-4 hover:bg-white hover:text-blue-950 text-sm hover:font-semibold  text-white"
                  >
                    Residential ESS
                  </a>
                  <a
                    href="/solutions/batterystorage/ciess"
                    className="block py-1 px-4 hover:bg-white hover:text-blue-950 text-sm hover:font-semibold text-white"
                  >
                    C&I ESS
                  </a>
                </div>
                <a
                  href="/solutions/digitalenergy"
                  className="block py-2 px-4 hover:bg-white hover:text-blue-950 hover:font-semibold  text-white"
                >
                  Digital Energy
                </a>
                <div className='ml-5 left-0'>
                  <a
                    href="/solutions/digitalenergy/meteringequipment"
                    className="block py-1 px-4 hover:bg-white hover:text-blue-950 text-sm hover:font-semibold  text-white"
                  >
                    Metering Equipment
                  </a>
                  <a
                    href="/solutions/digitalenergy/amicommunication"
                    className="block py-1 px-4 hover:bg-white hover:text-blue-950 text-sm hover:font-semibold rounded-br-lg text-white"
                  >
                    AMI/Communication
                  </a>
                </div>
              </div>
            )}
          </div>
          <a href="/whywaspo" className="px-3 py-2 hover:bg-white hover:text-blue-950 hover:font-semibold text-white rounded-lg">
            Why Us
          </a>
          <a href="/news" className="py-2 px-3 hover:bg-white hover:text-blue-950 hover:font-semibold text-white rounded-lg">
            News
          </a>
          <a href="/contacts" className="py-2 px-3 hover:bg-white hover:text-blue-950 hover:font-semibold text-white rounded-lg">
            Contact Us
          </a>
        </div>
      </div>

      {/* Mobile Navbar */}
      <div className="block lg:hidden py-3">
        <div className="flex items-center justify-between max-h-14">
          <div>
            <a href="/" className="text-xl font-bold">
              <img loading='lazy' src={LOGO} alt='logomobile' className='h-[7rem]' />
            </a>
          </div>
          <button
            className="mobile-menu-button focus:outline-none px-4"
            onClick={handleMobileMenuToggle}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
        {/* Mobile Dropdown */}
        <div className={`mt-3 ${isMobileMenuOpen ? 'block' : 'hidden'} mx-4`} ref={dropdownRef}>
          <a href="/" className="block py-2 hover:bg-white hover:text-blue-950 hover:font-semibold text-white">
            Home
          </a>
          <a href="/aboutus" className="block py-2 hover:bg-white hover:text-blue-950 hover:font-semibold text-white">
            About Us
          </a>
          <div
            className="relative inline-block"
            onMouseEnter={handleDropdownOpen}
            onMouseLeave={handleDropdownClose}
          >
            <button className="py-2 hover:bg-white hover:text-blue-950 hover:font-semibold text-white">
              <a href='/solutions'>Solutions</a>
            </button>
            {/* {isDropdownOpen && ( */}
            <div className="top-full left-0 mt-1 bg-gray-800 border border-gray-700 rounded-lg">
              {/* Add dropdown content here */}
              <a
                href="/solutions/germansolar"
                className="block py-2 px-4 hover:bg-white hover:text-blue-950 hover:font-semibold text-white"
              >
                Solar Energy
              </a>
              <div className='ml-5 left-0'>
                <a
                  href="/solutions/germansolar/highefficiencymodules"
                  className="block py-1 px-4 hover:bg-white hover:text-blue-950 hover:font-semibold  text-white"
                >
                  High Efficiency Modules
                </a>
                <a
                  href="/solutions/germansolar/customizedmodules"
                  className="block py-1 px-4 hover:bg-white hover:text-blue-950 hover:font-semibold text-white"
                >
                  Customized Modules
                </a>
              </div>
              <a
                href="/solutions/batterystorage"
                className="block py-2 px-4 hover:bg-white hover:text-blue-950 hover:font-semibold  text-white"
              >
                Battery Energy Storage
              </a>
              <div className='ml-5 left-0'>
                <a
                  href="/solutions/batterystorage/residentialess"
                  className="block py-1 px-4 hover:bg-white hover:text-blue-950 hover:font-semibold  text-white"
                >
                  Residential ESS
                </a>
                <a
                  href="/solutions/batterystorage/ciess"
                  className="block py-1 px-4 hover:bg-white hover:text-blue-950 hover:font-semibold text-white"
                >
                  C&I ESS
                </a>
              </div>
              <a
                href="/solutions/digitalenergy"
                className="block py-2 px-4 hover:bg-white hover:text-blue-950 hover:font-semibold  text-white"
              >
                Digital Energy
              </a>
              <div className='ml-5 left-0'>
                <a
                  href="/solutions/digitalenergy/meteringequipment"
                  className="block py-1 px-4 hover:bg-white hover:text-blue-950 hover:font-semibold  text-white"
                >
                  Metering Equipment
                </a>
                <a
                  href="/solutions/digitalenergy/amicommunication"
                  className="block py-1 px-4 hover:bg-white hover:text-blue-950 hover:font-semibold text-white"
                >
                  AMI/Communication
                </a>
              </div>
            </div>
          </div>
          <a href="/whywaspo" className="block py-2 hover:bg-white hover:text-blue-950 hover:font-semibold text-white">
            Why Us
          </a>
          <a href="/news" className="block py-2 hover:bg-white hover:text-blue-950 hover:font-semibold text-white">
            News
          </a>
          <a href="/contacts" className="block py-2 hover:bg-white hover:text-blue-950 hover:font-semibold text-white">
            Contact Us
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
