import { Breadcrumb } from 'flowbite-react'
import React from 'react'
import { HiOutlineArrowRight } from 'react-icons/hi'
import LOGO from '../assets/images/comcore.svg'
import IMG from '../assets/images/comcore.jpg'

const DigitalEnergy = () => {
    return (
        <div className='min-h-screen w-full mt-24 lg:px-56 px-5'>
            <h1 className='flex text-center justify-center text-3xl font-bold'>Digital Energy</h1>
            {/* <div className='flex justify-center items-center h-screen'> */}
            {/* </div> */}
            <div className='mt-10'>
                <Breadcrumb className='font-black text-2xl'>
                    <Breadcrumb.Item href='/'>
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/solutions`} >
                        Solutions
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/solutions/digitalenergy`} >
                        Digital Energy
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <img src={LOGO} alt='logo' className='mx-auto max-h-20 mt-10 w-44' />
            <img src={IMG} alt='logo' className='w-full lg:h-96 mt-8' />
            <div className='mt-10 flex flex-col'>
                <p className='font-semibold'>Comcore is a leading provider of Smart Energy Management solutions, offering end-to-end energy solutions that include smart metering.
                    Our global presence in the field of digital energy has earned us a reputation for success in smart manufacturing, AMI system solutions, IMC measurement clouds, and CNP carbon neutrality data management platforms.
                </p>
                <div className='flex lg:flex-row flex-col justify-between items-center gap-5 py-5'>
                    <div className="flex justify-end py-2">
                        <a href='/solutions/digitalenergy/meteringequipment' alt='learnMoreSolutions' className='bg-white flex items-center flex-row border rounded-lg border-blue-950 text-blue-950 hover:text-white hover:bg-blue-950 px-4 font-semibold py-3'>
                            <p>Learn more about our Metering Equipment</p>
                            <HiOutlineArrowRight className="ml-2 h-5 w-5" />
                        </a>
                    </div>
                    <div className="flex justify-end py-2">
                        <a href='/solutions/digitalenergy/amicommunication' alt='learnMoreSolutions' className='bg-white flex items-center flex-row border rounded-lg border-blue-950 text-blue-950 hover:text-white hover:bg-blue-950 px-4 font-semibold py-3'>
                            <p>Learn more about our AMI Communication</p>
                            <HiOutlineArrowRight className="ml-2 h-5 w-5" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalEnergy