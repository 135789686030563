import { Breadcrumb, Card } from 'flowbite-react'
import React from 'react'
import IMG from '../assets/images/certificate.png'
import IMG1 from '../assets/images/news1.jpg'
import IMG2 from '../assets/images/exhibition.jpg'

const News = () => {
    return (
        <div className='min-h-screen w-full mt-24 lg:px-56 px-5'>
            <h1 className='flex text-center justify-center text-2xl font-bold'>NEWS</h1>
            <div className='mt-10'>
                <Breadcrumb className='font-black text-2xl'>
                    <Breadcrumb.Item href='/'>
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/news`} >
                        News
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <div className='w-full flex flex-col gap-5 py-5'>
                <div className='w-full 2xl:h-[600px] lg:h-[400px] h-[300px] mb-5'>
                    <img
                        className='w-full h-full mb-5'
                        alt=''
                        src={IMG2}
                    />
                </div>
                <Card className="max-w-sm w-full" imgSrc={IMG} horizontal>
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        WASPO FZE
                    </h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400">
                        WASPO FZE, an authorized distributor of Bescore GmbH, specializes in delivering cutting-edge energy storage solutions to empower sustainable development initiatives in the Middle East, Africa, and Southeast Asia.
                    </p>
                </Card>
                <Card className="max-w-sm" imgSrc={IMG1} horizontal>
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        The Dynamic Partnership of WASPO FZE and Bescore GmbH
                    </h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400">
                        The collaboration between WASPO FZE and Bescore GmbH represents a strategic alliance aimed at revolutionizing the energy sector by seamlessly integrating Bescore's innovative technologies with WASPO's expertise in distribution and market reach, ushering in a new era of efficient and sustainable energy solutions.
                    </p>
                </Card>
            </div>
        </div>
    )
}

export default News